<template>
<div class="browse">
    <van-tabs v-model:active="active" @click="onChange" border="false" tab-class="tab-class" nav-class="nav-class" tab-active-class="tab-active-class" class="tabs">

        <van-tab title="跳蚤市场" name="1">
                    <van-list
        v-model:loading="draftloading"
        :finished="draftfinished"
        :finished-text="fleaList.length > 6 ? '没有更多了':''"
        @load="draftonLoad"
        >
        <NoData v-if="fleaList && fleaList.length == 0 " :bottomBg="true" ></NoData>
        <div class="reward-list">
            <div v-for="(item,index) in fleaList" :key="index">
                <div class="line">{{item.date}}</div>
                <div class="market-list">
                    <div class="market-list-items">
                        <flea :fleaData="item.data" type='ll' @updata="updata()"></flea>
                    </div>
                </div>
            </div>
             </div>
               </van-list>
        </van-tab>
      <van-tab title="悬赏问答" name="0">
        <div class="reward-list">
          <div class="reward-list-top">

          </div>
          <van-list
            v-model:loading="bountyloading"
            :finished="bountyfinished"
            :finished-text="communityList.length > 6 ? '没有更多了':''"
            @load="bountyonLoad"
          >
            <NoData v-if="communityList && communityList.length == 0 " :bottomBg="true" ></NoData>
            <div v-for="(item,index) in communityList" :key="index">
              <div class="line">{{item.date}}</div>
              <bounty :bountyData="item.data" type="ll" @updata="updata()"></bounty>
            </div>
          </van-list>
        </div>

      </van-tab>
      <van-tab title="资源互助" name="2">
        <div class="reward-list">
          <div class="reward-list-top">

          </div>
          <van-list
            v-model:loading="eleloading"
            :finished="elefinished"
            :finished-text="eleList.length > 5 ? '没有更多了':''"
            @load="eleonLoad"
          >
            <NoData v-if="eleList && eleList.length == 0 " :bottomBg="true" ></NoData>
            <div v-for="(item,index) in eleList" :key="index">
              <div class="line">{{item.date}}</div>
              <resources :electronData="item.data" type="ll" @updata="updata()"></resources>
            </div>
          </van-list>
        </div>

      </van-tab>
    </van-tabs>
</div>

</template>

<script>
import NoData from "components/NoData";
import UserInfo from "components/userInfo";
import bounty from "../../../components/bounty"
import flea from "../../../components/flea"
import resources from "../../../components/resources"
export default {
  name: "user.browse",
  data(){
    return{
      active: '1',
        communityList:[],
        page:1,
        load:true,
        fleaList:[],
        fleapage:1,
        fleaLoad:true,
        bountyloading:false,
        bountyfinished:true,
        draftloading:false,
        draftfinished:true,
      eleList:[],
      elepage:1,
      eleload:true,
      eleloading:false,
      elefinished:true,
    }
  },

    created() {
         if (this.$store.getters.act) {
        this.active = this.$store.getters.act
         this.$store.commit('M_ACT','1')
      }
      this.getfleaList()
      this.getcommunityList()
      this.geteleList()
  },
  methods:{
 //跳蚤市场列表
     getfleaList(){
        let url = this.$api.communityBrowse + '?model_type=flea&pageSize=6&page=' + this.fleapage 
         this.$http.get(url, true).then(res => {
          if(res.data.success){
            if(res.data.data.length){
        if(this.fleaList.length && res.data.data[0].date == this.fleaList[this.fleaList.length-1].date){
                this.fleaList[this.fleaList.length-1].data = this.fleaList[this.fleaList.length-1].data.concat(res.data.data[0].data)
                this.fleaList=this.fleaList.concat(res.data.data.slice(1)),
                  this.fleapage=this.fleapage + 1
              }else{
                this.fleaList = this.fleaList.concat(res.data.data)
                this.fleapage = this.fleapage + 1
              }
                   this.draftloading = false
                 this.draftfinished = false
            }else{
                this.fleaLoad = false
                 this.draftfinished = true
            }
          }
        })
      },
      //悬赏帮助列表
      getcommunityList(){
      let url = this.$api.communityBrowse + '?model_type=bounty&pageSize=5&page=' + this.page
       this.$http.get(url, true).then(res => {
        if(res.data.success){
          if(res.data.data.length){

      if(this.communityList.length && res.data.data[0].date == this.communityList[this.communityList.length-1].date){
              this.communityList[this.communityList.length-1].data = this.communityList[this.communityList.length-1].data.concat(res.data.data[0].data)
              this.communityList=this.communityList.concat(res.data.data.slice(1))
                this.page=this.page + 1
            }else{
              this.communityList = this.communityList.concat(res.data.data),
              this.page = this.page + 1
            }

                this.bountyloading = false;
                this.bountyfinished = false
          }else{
              this.load = false
                 this.bountyfinished = true
          }
        }
      })
      },
    //电子资源列表
    geteleList(){
      let url = this.$api.communityBrowse + '?model_type=electron&pageSize=5&page=' + this.elepage
      this.$http.get(url, true).then(res => {
        if(res.data.success){
          if(res.data.data.length){

            if(this.eleList.length && res.data.data[0].date == this.eleList[this.eleList.length-1].date){
              this.eleList[this.eleList.length-1].data = this.eleList[this.eleList.length-1].data.concat(res.data.data[0].data)
              this.eleList=this.eleList.concat(res.data.data.slice(1))
              this.elepage=this.elepage + 1
            }else{
              this.eleList = this.eleList.concat(res.data.data),
                this.elepage = this.elepage + 1
            }

            this.eleloading = false;
            this.elefinished = false
          }else{
            this.eleload = false
            this.elefinished = true
          }
        }
      })
    },
    onChange(event) {
        this.active = event
        },
         bountyonLoad(){
        if(this.active == 0 && this.load && this.page != 1){
          this.getcommunityList()
        }
      },
      draftonLoad(){
          if(this.active == 1 && this.fleaLoad && this.fleapage != 1){
          this.getfleaList()
        }
      },
    eleonLoad(){
      if(this.active == 2 && this.eleload && this.elepage != 1){
        this.geteleList()
      }
    },
      updata(){
        this.communityList=[]
        this.page=1
        this.load=true
        this.fleaList=[]
        this.fleapage=1
        this.fleaLoad=true
        this.eleList=[],
          this.elepage=1,
          this.eleload=true,
        this.getfleaList()
      this.getcommunityList()
        this.geteleList()
      }
  },
  components:{
    UserInfo,
    bounty,
    flea,
    resources,
    NoData
  },
   computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/browse/browse.scss";
</style>
<style lang="scss">
$r: 750/16/1rem;
.browse .van-tabs__wrap{
    position: fixed;
    width: 100%;
    top: 84/$r;
    z-index: 99;
  }
.browse .van-tabs__line {
    width: 0.8533333333rem !important;
    height: 0.128rem;
    background: #FF6B29;
    border-radius: 0.064rem;
}
</style>